@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.root {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(316.19deg, #031545 0%, #007cc2 100%);
  padding: 16px;
  margin: 20px 0px;
  @media screen and (max-width: 959.95px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.messageContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  flex: 1;
  color: white;
}
.goToButton {
  width: 180px;
  @media screen and (max-width: 959.95px) {
    width: 100%;
    margin-top: 12px !important;
  }
}
.closeButton {
  position: absolute !important;
  height: auto !important;
  top: 12px;
  right: 12px;
  padding: 0px !important;
}

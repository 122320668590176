@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.AdRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  background-color: #dbf0f9;
  height: 100vh;
  width: 100vw;
}
.Ad {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-height: 1400px;
  max-width: 1400px;
  margin: 0px;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
}
.AdClose {
  position: absolute;
  top: 20px;
  right: 20px;
}
.AdImageWrapper {
  width: 500px;
  @media screen and (max-width: 720px) {
    width: 180px;
  }
}
.AdContent {
  padding: 20px 24px;
  @media screen and (max-width: 720px) {
    width: 100%;
  }
}

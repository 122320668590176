@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.importantReadContainer {
  display: flex;
  flex-direction: column;
}
.flexDisplay {
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  @media screen and (max-width: 1279.95px) {
    gap: 12px;
  }
  @media screen and (max-width: 599.95px) {
    flex-direction: column;
  }
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

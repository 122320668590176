@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.optOut {
  margin-top: 20px;
  display: grid;
  gap: 10px;
  padding: 20px;
  background-color: $achromatic-white;
  border: 1px solid $color-dark-blue-25;
  border-radius: 6px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

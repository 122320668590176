@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.noEventsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 24px);
  text-align: center;
  @media screen and (max-width: $md) {
    margin-top: 0px;
  }
}

@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.razzmatazz {
  background: #e0004d;
}
.flame {
  background: #dc6b2f;
}
.plum {
  background: #93328e;
}
.mintGreen {
  background: #00c19f;
}
.havenBlue {
  background: $blue-primary-v100;
}

.root {
  position: relative;
  height: 152px;
  overflow: hidden;
  margin-bottom: 24px;
  img {
    width: 483px;
    position: absolute;
    top: -30px;
    left: -70px;
  }
  @media screen and (max-width: 900px) {
    height: 229px;
    img {
      top: -30px;
      left: -85px;
    }
  }
  @media screen and (max-width: 600px) {
    height: 300px;
    img {
      width: 598px;
      left: calc((100% - 575px) / 2);
      top: -70px;
    }
  }
}
.curve {
  position: absolute;
  top: 0;
  left: 230px;
  width: 230px;
  height: 100%;
  clip-path: circle(100% at 100% 50%);
  @media screen and (max-width: 900px) {
    left: 190px;
  }
  @media screen and (max-width: 600px) {
    top: 85px;
    left: calc((100vw - 600px) / 2);
    width: 566px;
    height: 405px;
    clip-path: ellipse(100% 90% at 50% 100%);
  }
}
.label {
  background-color: #031545;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 169px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  p {
    font-weight: $fw-normal;
  }
}
.body {
  height: 100%;
  width: calc(100% - 322px);
  position: absolute;
  left: 289px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  @media screen and (max-width: 1200px) {
    left: 260px;
    width: calc(100% - 282px);
  }
  @media screen and (max-width: 900px) {
    left: 229px;
    width: calc(100% - 262px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  @media screen and (max-width: 600px) {
    left: 0;
    bottom: 0;
    padding: 16px;
    height: auto;
    width: 100%;
    justify-content: space-between;
  }
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  margin-right: 10px;
  h1 {
    margin: 0px;
  }
  h4 {
    margin: 0px;
  }
  @media screen and (min-width: 1200px) {
    h1 {
      font-size: 35px;
      line-height: 50px;
    }
    h4 {
      font-size: 16px;
      font-weight: $fw-normal;
    }
  }
  @media screen and (max-width: 800px) {
    h1 {
      font-size: 28px;
      line-height: 32px;
      margin: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    h4 {
      font-size: 14px;
      line-height: 1.25;
    }
  }
}
.link {
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 4px;
  min-width: 120px;
  height: 38px;
  @media screen and (max-width: 900px) {
    margin-top: 16px;
  }
}

@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.activitiesContainer {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  padding: 12px 0px 24px;
  border-radius: 8px;
  @media screen and (max-width: $md) {
    gap: 12px;
    width: 100%;
  }
}
.onlyOwners {
  padding: 12px 0 24px 24px;
  margin-top: 16px;
  background: #f9f9f9;
  @media screen and (max-width: $md) {
    margin-top: 0px;
  }
}
.noUpcomingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 24px);
  margin-top: 28px;
  height: 182px;
  color: #575757;
  text-align: center;
  @media screen and (min-width: $md) {
    height: 100px;
    margin-top: 0px;
  }
}
.bookButton {
  margin-top: 14px !important;
  background-color: #f9f9f9 !important;
}

@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.activitiesTitle {
  display: flex;
  align-items: center;
  width: calc(100% - 24px);
  height: 58px;
  border-bottom: thin solid $achromatic-light-silver;
  & > h4 {
    font-size: 24px;
    font-weight: $fw-normal;
    margin: 0px;
  }
}

@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.AdRoot {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: #ffffff99;
  height: 100%;
  width: 100%;
}
.Ad {
  background-color: #dbf0f9;
  height: min-content;
  max-width: 720px;
  display: flex;
  position: relative;
  margin: 0px 24px;
  max-height: 95%;
  overflow: scroll;
}
.AdClose {
  position: absolute;
  top: 0;
  right: 0;
}
.AdImage {
  background-image: url(/assets/letting/lettings-ad.jpg);
  width: 288px;
  background-position-x: -100px;
  background-size: cover;
  @media screen and (max-width: 720px) {
    display: none;
  }
}
.AdContent {
  width: calc(100% - 288px);
  padding: 20px 24px;
  @media screen and (max-width: 720px) {
    width: 100%;
  }
}

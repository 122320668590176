@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.activityContainer {
  transition: all 0.7s ease-in-out;
  cursor: pointer;
  padding: 0px 0px 12px 0px !important;
  width: calc(100% - 24px);
  border-bottom: 1px solid $achromatic-light-silver;
}
.notOwners {
  @media screen and (min-width: $md) {
    width: 100%;
  }
}
.details {
  display: flex;
  align-items: center;
  font-weight: $fw-normal;
  font-size: 14px;
  color: #031545;
  flex-wrap: wrap;
}
.dateTime {
  display: flex;
  align-items: center;
  margin-left: 12px;
  & span {
    margin-top: 3px;
    margin-left: 4px;
  }
}
.separator {
  @media screen and (min-width: $xs) {
    display: none;
  }
}
.timeSlots {
  & > div {
    display: flex;
  }
  @media screen and (min-width: $xs) {
    width: 100%;
    margin-left: 0px;
    margin-top: 8px;
  }
}
.extraTimeSlots {
  margin-left: 0px !important;
}
.link {
  color: $blue-primary-v100;
  font-size: 16px;
  line-height: 28px;
  font-weight: $fw-normal;
  cursor: pointer;
  margin-left: auto;
  @media screen and (min-width: $md) {
    margin-left: 0px;
    margin-top: 6px;
    width: 100%;
  }
}
.title {
  margin: 0px 0px 8px;
  font-weight: $fw-bold;
  display: flex;
  align-items: center;
}
.underlined {
  text-decoration: underline;
}
.chevron {
  transform: rotate(270deg);
}

@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.gutterBottom {
  margin-bottom: 12px;
}
.gutter-top {
  margin-top: 12px;
}
.sectionHeaderLink {
  color: $blue-primary-v100 !important;
  font-size: 16px;
  line-height: 28px;
  text-decoration: underline !important;
  font-weight: $fw-normal;
  cursor: pointer;
}
.activitiesWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activitiesLoading {
  min-height: 210px;
  @media screen and (max-width: $sm) {
    min-height: 250px;
  }
}
